import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
import { utcParse } from "d3-time-format";
import useBaseUtils from "~/functions/useBaseUtils";
var parseT = utcParse("%Y-%m-%dT%H:%M:%S.%LZ");
export default defineComponent({
  validate: function validate(_ref) {
    var query = _ref.query; // return (
    //   /^\d+$/.test(query.ts) && /^\d+$/.test(query.e) && /^\d+$/.test(query.a)
    // )
    return /^\d+$/.test(query.ts) && /^\d+$/.test(query.e) && /^\d+$/.test(query.tid) && /^\d+$/.test(query.cid);
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      dev = _useBaseUtils.dev;
    return {
      dev: dev
    };
  },
  computed: {
    transcript: function transcript() {
      // was going to add logic around loading...
      // but either the transcriptId will be on the object transcript or it wont
      // if it isn't there (I'm assuming transcript is always an object)
      // then we can just use || [] to return an array and not get ane rror
      var componentArray = this.$store.state.ciq.transcripts[this.$route.query.ts] || [];
      if (componentArray === "unauth") {
        return "unauth";
      } else {
        return componentArray.map(this.transformComponent);
      }
    },
    unauth: function unauth() {
      return this.transcript === "unauth";
    },
    transcriptObj: function transcriptObj() {
      var tid = this.$route.query.ts;
      return this.$store.state.ciq.transcriptList.filter(function (t) {
        return t.transcriptid === parseInt(tid);
      })[0] || {
        headline: ""
      };
    },
    formatTime: function formatTime() {
      var lang = navigator.language || "default";
      return new Intl.DateTimeFormat(lang, {
        year: "2-digit",
        month: "numeric",
        day: "numeric"
      });
    },
    loading: function loading() {
      return this.$store.state.ciq.fetchingSingleTranscript;
    },
    error: function error() {
      return this.$store.state.ciq.getTranscriptError;
    },
    keydevid: function keydevid() {
      return this.$route.query.e;
    },
    transcriptid: function transcriptid() {
      return this.$route.query.ts;
    },
    tradingitemid: function tradingitemid() {
      return this.$route.query.tid;
    },
    companyid: function companyid() {
      return this.$route.query.cid;
    },
    queryStr: function queryStr() {
      var keydevid = this.keydevid;
      var transcriptid = this.transcriptid;
      var tid = this.tradingitemid;
      var cid = this.companyid;
      if (keydevid && transcriptid && tid && cid) {
        return "".concat(tid, "-").concat(cid, "-").concat(keydevid, "-").concat(transcriptid);
      } else {
        return false;
      }
    }
  },
  watch: {
    queryStr: {
      immediate: true,
      handler: function handler(newVal) {
        if (newVal) {
          var _newVal$split = newVal.split("-"),
            _newVal$split2 = _slicedToArray(_newVal$split, 4),
            tradingitemid = _newVal$split2[0],
            companyid = _newVal$split2[1],
            keydevid = _newVal$split2[2],
            transcriptid = _newVal$split2[3];
          var payload = {
            tradingitemid: tradingitemid,
            companyid: companyid,
            keydevid: keydevid,
            transcriptid: transcriptid
          };
          this.$store.dispatch("ciq/fetchTranscript", payload);
        }
      }
    }
  },
  methods: {
    parseTime: function parseTime(utcDate) {
      if (utcDate) {
        return parseT(utcDate);
      }
    },
    transformComponent: function transformComponent(tObj) {
      /**
      * transformComponent takes a transcript component object and splits
      * the tObj.component text into an array containing strings of five senteces or more?
      * this is used in a map function
      *
      * splits the componenttext (one long string) by ". " (space included)
      */
      if (tObj.componenttext) {
        var sentences = tObj.componenttext.split("\n"); // sentences is an array of strings, want to return an array of strings that only contain 5 senteces...
        tObj.textSplit = sentences;
        return tObj;
      } else {
        tObj.textSplit = [];
        return tObj;
      }
    },
    groupSentences: function groupSentences(acc, s, idx, arr) {
      return acc;
    }
  }
});